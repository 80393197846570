export enum DocumentSignRequestRecordType {
  STAMP_DOCUMENT_SIGN_REQUEST = 'STAMP_DOCUMENT_SIGN_REQUEST',
  TEMPLATE_DOCUMENT_SIGN_REQUEST = 'TEMPLATE_DOCUMENT_SIGN_REQUEST',
}

export enum PartyRecordType {
  OPERATOR = 'OPERATOR',
  SHIPPER = 'SHIPPER',
  FORWARDER = 'FORWARDER',
  US_CONSIGNEE = 'US_CONSIGNEE',
  GB_CONSIGNEE = 'GB_CONSIGNEE',
  DE_CONSIGNEE = 'DE_CONSIGNEE',
  NL_CONSIGNEE = 'NL_CONSIGNEE',
  FR_CONSIGNEE = 'FR_CONSIGNEE',
  GLOBAL_CONSIGNEE = 'GLOBAL_CONSIGNEE',
  US_IOR = 'US_IOR',
  DE_IOR = 'DE_IOR',
  GB_IOR = 'GB_IOR',
  NL_IOR = 'NL_IOR',
  FR_IOR = 'FR_IOR',
  IMPORTER = 'IMPORTER',
  TRUCKER = 'TRUCKER',
  FACILITY = 'FACILITY',
  SUPPLIER = 'SUPPLIER',
  CUSTOMS_AGENT = 'CUSTOMS_AGENT',
  US_CUSTOMS_BROKER = 'US_CUSTOMS_BROKER',
  UK_CUSTOMS_BROKER = 'UK_CUSTOMS_BROKER',
  DE_CUSTOMS_BROKER = 'DE_CUSTOMS_BROKER',
  NL_CUSTOMS_BROKER = 'NL_CUSTOMS_BROKER',
  FR_CUSTOMS_BROKER = 'FR_CUSTOMS_BROKER',
  BROKER = 'BROKER',
}

export enum ExternalSystemRecordType {
  SHIPPER_SYSTEM = 'SHIPPER_SYSTEM',
  FORWARDER_SYSTEM = 'FORWARDER_SYSTEM',
  OPERATOR_SYSTEM = 'OPERATOR_SYSTEM',
}

export enum UserRecordType {
  SHIPPER_USER = 'SHIPPER_USER',
  FORWARDER_USER = 'FORWARDER_USER',
  OPERATOR_USER = 'OPERATOR_USER',
}

export enum UserTeamRecordType {
  ASSIGNMENT_TEAM = 'ASSIGNMENT_TEAM',
  BUSINESS_SUPPORT_TEAM = 'BUSINESS_SUPPORT_TEAM',
  SUBJECT_MATTER_EXPERT_TEAM = 'SUBJECT_MATTER_EXPERT_TEAM',
}

export enum AuditLogRecordType {
  AUDIT_LOG = 'AUDIT_LOG',
}

export const isUser = (recordType: RecordType) =>
  Object.keys(UserRecordType).includes(recordType);

export enum WorkOrderRecordType {
  US_ISF = 'US_ISF',
  US_CONSUMPTION_ENTRY = 'US_CONSUMPTION_ENTRY',
  US_TYPE86_ENTRY = 'US_TYPE86_ENTRY',
  US_IN_BOND = 'US_IN_BOND',
  US_DRAWBACK_CLAIM = 'US_DRAWBACK_CLAIM',
  US_IOR_CONTINUOUS_BOND_REQUEST = 'US_IOR_CONTINUOUS_BOND_REQUEST',
  US_IOR_ACTIVATION = 'US_IOR_ACTIVATION',
  US_POST_SUMMARY_CORRECTION = 'US_POST_SUMMARY_CORRECTION',
  DELIVERY_ORDER = 'DELIVERY_ORDER',
  GB_CUSTOMS_ENTRY = 'GB_CUSTOMS_ENTRY',
  DE_CUSTOMS_ENTRY = 'DE_CUSTOMS_ENTRY',
  NL_CUSTOMS_ENTRY = 'NL_CUSTOMS_ENTRY',
  FR_CUSTOMS_ENTRY = 'FR_CUSTOMS_ENTRY',
  GB_IOR_ACTIVATION = 'GB_IOR_ACTIVATION',
  DE_IOR_ACTIVATION = 'DE_IOR_ACTIVATION',
  NL_IOR_ACTIVATION = 'NL_IOR_ACTIVATION',
  FR_IOR_ACTIVATION = 'FR_IOR_ACTIVATION',
  IMPORTER_ENTITY_ACTIVATION = 'IMPORTER_ENTITY_ACTIVATION',
  CUSTOMS_DECLARATION = 'CUSTOMS_DECLARATION',
}

export const isWorkOrder = (recordType: RecordType) =>
  Object.keys(WorkOrderRecordType).includes(recordType);

export enum DocumentGenerationRequestRecordType {
  US_CARGO_RELEASE_DOCUMENT_GENERATION_REQUEST = 'US_CARGO_RELEASE_DOCUMENT_GENERATION_REQUEST',
  US_CBP_7501_DOCUMENT_GENERATION_REQUEST = 'US_CBP_7501_DOCUMENT_GENERATION_REQUEST',
  US_CBP_7512_DOCUMENT_GENERATION_REQUEST = 'US_CBP_7512_DOCUMENT_GENERATION_REQUEST',
  INVOICE_DOCUMENT_GENERATION_REQUEST = 'INVOICE_DOCUMENT_GENERATION_REQUEST',
  INVOICE_CREDIT_NOTE_DOCUMENT_GENERATION_REQUEST = 'INVOICE_CREDIT_NOTE_DOCUMENT_GENERATION_REQUEST',
  STAMP_DOCUMENT_GENERATION_REQUEST = 'STAMP_DOCUMENT_GENERATION_REQUEST',
  IN_BOND_NOTES_DOCUMENT_GENERATION_REQUEST = 'IN_BOND_NOTES_DOCUMENT_GENERATION_REQUEST',
  DELIVERY_ORDER_DOCUMENT_GENERATION_REQUEST = 'DELIVERY_ORDER_DOCUMENT_GENERATION_REQUEST',
  US_CBP_5106_DOCUMENT_GENERATION_REQUEST = 'US_CBP_5106_DOCUMENT_GENERATION_REQUEST',
}

export enum NoInterfaceRecordType {
  // custom domain
  CUSTOM_DOMAIN = 'CUSTOM_DOMAIN',

  // party
  IMPORTER_ENTITY = 'IMPORTER_ENTITY',
  TEAM = 'TEAM',
  SHIPPER_FORWARDER_RELATIONSHIP = 'SHIPPER_FORWARDER_RELATIONSHIP',
  SHIPPER_SUPPLIER_RELATIONSHIP = 'SHIPPER_SUPPLIER_RELATIONSHIP',
  SHIPPER_FACILITY_RELATIONSHIP = 'SHIPPER_FACILITY_RELATIONSHIP',
  SHIPPER_TRUCKER_RELATIONSHIP = 'SHIPPER_TRUCKER_RELATIONSHIP',
  SHIPPER_CUSTOMS_AGENT_RELATIONSHIP = 'SHIPPER_CUSTOMS_AGENT_RELATIONSHIP',
  SHIPPER_US_IOR_RELATIONSHIP = 'SHIPPER_US_IOR_RELATIONSHIP',
  SHIPPER_DE_IOR_RELATIONSHIP = 'SHIPPER_DE_IOR_RELATIONSHIP',
  SHIPPER_GB_IOR_RELATIONSHIP = 'SHIPPER_GB_IOR_RELATIONSHIP',
  SHIPPER_NL_IOR_RELATIONSHIP = 'SHIPPER_NL_IOR_RELATIONSHIP',
  SHIPPER_FR_IOR_RELATIONSHIP = 'SHIPPER_FR_IOR_RELATIONSHIP',
  SHIPPER_US_CONSIGNEE_RELATIONSHIP = 'SHIPPER_US_CONSIGNEE_RELATIONSHIP',
  SHIPPER_GB_CONSIGNEE_RELATIONSHIP = 'SHIPPER_GB_CONSIGNEE_RELATIONSHIP',
  SHIPPER_DE_CONSIGNEE_RELATIONSHIP = 'SHIPPER_DE_CONSIGNEE_RELATIONSHIP',
  SHIPPER_NL_CONSIGNEE_RELATIONSHIP = 'SHIPPER_NL_CONSIGNEE_RELATIONSHIP',
  SHIPPER_FR_CONSIGNEE_RELATIONSHIP = 'SHIPPER_FR_CONSIGNEE_RELATIONSHIP',
  SHIPPER_GLOBAL_CONSIGNEE_RELATIONSHIP = 'SHIPPER_GLOBAL_CONSIGNEE_RELATIONSHIP',
  SHIPPER_US_CUSTOMS_BROKER_RELATIONSHIP = 'SHIPPER_US_CUSTOMS_BROKER_RELATIONSHIP',
  SHIPPER_UK_CUSTOMS_BROKER_RELATIONSHIP = 'SHIPPER_UK_CUSTOMS_BROKER_RELATIONSHIP',
  SHIPPER_DE_CUSTOMS_BROKER_RELATIONSHIP = 'SHIPPER_DE_CUSTOMS_BROKER_RELATIONSHIP',
  SHIPPER_NL_CUSTOMS_BROKER_RELATIONSHIP = 'SHIPPER_NL_CUSTOMS_BROKER_RELATIONSHIP',
  SHIPPER_FR_CUSTOMS_BROKER_RELATIONSHIP = 'SHIPPER_FR_CUSTOMS_BROKER_RELATIONSHIP',
  SHIPPER_BROKER_RELATIONSHIP = 'SHIPPER_BROKER_RELATIONSHIP',
  IMPORTER_IMPORTER_ENTITY_RELATIONSHIP = 'IMPORTER_IMPORTER_ENTITY_RELATIONSHIP',
  SHIPPER_IMPORTER_RELATIONSHIP = 'SHIPPER_IMPORTER_RELATIONSHIP',

  // finance
  INVOICE = 'INVOICE',
  PAYABLE_INVOICE = 'PAYABLE_INVOICE',
  INVOICE_LINES_REQUEST = 'INVOICE_LINES_REQUEST',
  INVOICE_PAYMENT = 'INVOICE_PAYMENT',
  INVOICE_CREDIT_NOTE = 'INVOICE_CREDIT_NOTE',
  REPORT_RECONCILIATION_REQUEST = 'REPORT_RECONCILIATION_REQUEST',

  // shipment
  SHIPMENT = 'SHIPMENT',

  // work order
  WORK_ORDER_MILESTONE = 'WORK_ORDER_MILESTONE',
  WORK_ORDER_TASK = 'WORK_ORDER_TASK',
  WORK_ORDER_GROUP = 'WORK_ORDER_GROUP',
  WORK_ORDER_CONFIRMATION = 'WORK_ORDER_CONFIRMATION',
  WORK_ORDER_TASK_DEFINITION = 'WORK_ORDER_TASK_DEFINITION',
  WORK_ORDER_STATE_MACHINE = 'WORK_ORDER_STATE_MACHINE',
  BULK_MILESTONE_UPLOAD_REQUEST = 'BULK_MILESTONE_UPLOAD_REQUEST',
  BULK_CHARGES_UPLOAD_REQUEST = 'BULK_CHARGES_UPLOAD_REQUEST',
  BULK_COMPLIANCE_UPLOAD_REQUEST = 'BULK_COMPLIANCE_UPLOAD_REQUEST',
  WORK_ORDER_TASK_ESCALATION_MESSAGE = 'WORK_ORDER_TASK_ESCALATION_MESSAGE',

  // product
  PRODUCT = 'PRODUCT',
  IMPORT_PRODUCTS_FROM_BULK_UPLOAD_REQUEST = 'IMPORT_PRODUCTS_FROM_BULK_UPLOAD_REQUEST',
  CLASSIFICATION = 'CLASSIFICATION',

  // document
  DOCUMENT = 'DOCUMENT',
  DOCUMENT_TEMPLATE = 'DOCUMENT_TEMPLATE',

  // trade data
  HTS = 'HTS',
  US_HTS = 'US_HTS',
  // TODO: deprecate
  ADCVD_CASE = 'ADCVD_CASE',
  AD_CVD_CASE = 'AD_CVD_CASE',
  US_PRINCIPAL_AD_CVD_CASE = 'US_PRINCIPAL_AD_CVD_CASE',
  COMMODITY_GROUP = 'COMMODITY_GROUP',

  // client
  AUTHENTICATION_TOKEN = 'AUTHENTICATION_TOKEN',
  MEMO = 'MEMO',
  ONBOARDING_REQUEST = 'ONBOARDING_REQUEST',
  TABLE_FILTER = 'TABLE_FILTER',
  CHANGELOG = 'CHANGELOG',
  ASSIGNMENT_TEAM_OPERATOR_USER_RELATIONSHIP = 'ASSIGNMENT_TEAM_OPERATOR_USER_RELATIONSHIP',
  BUSINESS_SUPPORT_TEAM_OPERATOR_USER_RELATIONSHIP = 'BUSINESS_SUPPORT_TEAM_OPERATOR_USER_RELATIONSHIP',
  SUBJECT_MATTER_EXPERT_TEAM_OPERATOR_USER_RELATIONSHIP = 'SUBJECT_MATTER_EXPERT_TEAM_OPERATOR_USER_RELATIONSHIP',
  FEATURE_FLAG = 'FEATURE_FLAG',
}

export enum RecordInterfaceType {
  WORK_ORDER = 'WORK_ORDER',
  PARTY = 'PARTY',
  CUSTOMS_BROKER = 'CUSTOMS_BROKER',
  USER = 'USER',
  OPERATOR_TEAM = 'OPERATOR_TEAM',
  EXTERNAL_SYSTEM = 'EXTERNAL_SYSTEM',
  DOCUMENT_GENERATION_REQUEST = 'DOCUMENT_GENERATION_REQUEST',
  CUSTOMS_ENTRY = 'CUSTOMS_ENTRY',
}

export enum MessageExchangeRecordType {
  EXCHANGE_MESSAGE = 'EXCHANGE_MESSAGE',
}

export enum ProcessingErrorRecordType {
  PROCESSING_ERROR = 'PROCESSING_ERROR',
}

export enum ErrorDefinitionRecordType {
  ERROR_DEFINITION = 'ERROR_DEFINITION',
}

// Here's an explanation of enum merging: https://stackoverflow.com/questions/48478361/how-to-merge-two-enums-in-typescript
export const RecordType = {
  ...NoInterfaceRecordType,
  ...WorkOrderRecordType,
  ...PartyRecordType,
  ...DocumentSignRequestRecordType,
  ...UserRecordType,
  ...UserTeamRecordType,
  ...ExternalSystemRecordType,
  ...DocumentGenerationRequestRecordType,
  ...MessageExchangeRecordType,
  ...ProcessingErrorRecordType,
  ...ErrorDefinitionRecordType,
  ...AuditLogRecordType,
};
export type RecordType =
  | NoInterfaceRecordType
  | WorkOrderRecordType
  | PartyRecordType
  | DocumentSignRequestRecordType
  | UserRecordType
  | UserTeamRecordType
  | ExternalSystemRecordType
  | DocumentGenerationRequestRecordType
  | MessageExchangeRecordType
  | ProcessingErrorRecordType
  | ErrorDefinitionRecordType
  | AuditLogRecordType;

export const ObjectType = {
  ...RecordType,
  ...RecordInterfaceType,
};
export type ObjectType = RecordType | RecordInterfaceType;

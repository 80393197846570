import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

export const ssoFeature = 'SSO';

const SSO = new FeatureFlagService([
  {
    feature: ssoFeature,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA],
  },
]);

export { SSO };
